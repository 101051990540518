import { createStore } from 'redux'

const initialState = {
  sidebarShow: true,
  asideShow: false,
  dailySidebarShow: false,
  sidebarUnfoldable: false,
  selectedDtControl: 'state',
  cloudModelSelection: [],
  localModelSelection: [],
  lastblock: {},
  theme: 'light',
  stateToColor: [
    { value: 0 },
    { value: 1 },
    { value: 2 }
  ],
  specToColor: [],
  isAmodelOpen: false
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store
