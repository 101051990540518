export const dashTranslations = {
  spanish: {
    dashboard: {
      kpis: {
        title: "KPI's",
        items: [
          {
            alias: 'Avance físico real',
            content: 'Avance que se ha logrado en la obra hasta el momento actual.'
          },
          {
            alias: 'Avance programado',
            content: 'Avance que se espera que tenga la obra en el momento actual.'
          },
          {
            alias: 'Días de atraso',
            content: 'Días de atraso generados por los desplazamientos en la obra.'
          },
          {
            alias: 'Bloques alta prioridad',
            content: 'Cantidad de bloques disponibles a ejecutar el día de hoy en ruta crítica.'
          },
          {
            alias: 'Bloques baja prioridad',
            content: 'Cantidad de bloques disponibles a ejecutar el día de hoy en ruta simple.'
          }
        ]
      },
      chart: {
        title: 'Avance general de obra'
      },
      specialityAdv: {
        title: 'Avance por especialidad'
      }
    }
  },
  english: {
    dashboard: {
      kpis: {
        title: "KPI's",
        items: [
          {
            alias: 'Real physical progress',
            content: 'Progress which has been achieved at work to date.'
          },
          {
            alias: 'Scheduled progress',
            content: 'Progress that the work is expected to have at present.'
          },
          {
            alias: 'Delayed days',
            content: 'Delay days due to displacements at work.'
          },
          {
            alias: 'Delayed critical blocks',
            content: 'Number of available blocks to be executed today on the critical path.'
          },
          {
            alias: 'Delayed simple blocks',
            content: 'Number of available blocks to be executed today on the simple path.'
          }
        ]
      },
      chart: {
        title: 'Work general progress'
      },
      specialityAdv: {
        title: 'Advancement by specialty'
      }
    }
  }
}

export const dtTranslations = {
  spanish: {
    filter: {
      action: 'Filtrar',
      header: 'Filtre por etiquetas los bloques que desea visualizar',
      dates: {
        title: 'Fechas',
        from: 'Desde',
        to: 'Hasta'
      },
      titles: ['Especialidades', 'Fases', 'Niveles', 'Otras'],
      buttons: [
        'Limpiar',
        'Filtrar'
      ]
    },
    sequence: {
      action: 'Secuencia'
    },
    specialties: {
      action: 'Especialidad',
      header: 'Seleccione las especialidades que desea visualizar',
      onClose: [
        'Cerrar',
        'Seleccionar'
      ]
    },
    visibility: {
      action: 'Estado',
      onClose: [
        'Cerrar',
        'Seleccionar'
      ]
    },
    modelSelection: {
      action: 'Nube',
      title: 'Seleccione el modelo que desea ver',
      buttons: [
        'Descargar',
        'Aplicar'
      ]
    },
    localModelSelection: {
      action: 'Local',
      title: 'Seleccione el modelo que desea ver',
      buttons: [
        'Cargar',
        'Aplicar'
      ],
      placeholder: 'Cargá un modelo local'
    }
  },
  english: {
    filter: {
      action: 'Filter',
      header: 'Filter by tags the blocks you want to visualize',
      dates: {
        title: 'Dates',
        from: 'From',
        to: 'To'
      },
      titles: ['Specialties', 'Phases', 'Contractors', 'Others'],
      buttons: [
        'Clear',
        'Filter'
      ]
    },
    sequence: {
      action: 'Sequence'
    },
    specialties: {
      action: 'Specialty',
      header: 'Select the specialties you want to visualize',
      onClose: [
        'Close',
        'Select'
      ]
    },
    visibility: {
      action: 'Status',
      onClose: [
        'Close',
        'Select'
      ]
    },
    modelSelection: {
      action: 'Cloud',
      title: 'Select the model you want to see',
      buttons: [
        'Download',
        'Apply'
      ]
    },
    localModelSelection: {
      action: 'Local',
      title: 'Select the model you want to see',
      buttons: [
        'Load',
        'Apply'
      ],
      placeholder: 'Load a local model'
    }
  }
}

export const blockModalTranslations = {
  spanish: {
    title: [
      'Bloque',
      'Descripción'
    ],
    table: {
      item_1: 'Fecha programada',
      item_2: 'Fecha de reprogramación',
      item_3: 'Fecha de ejecución',
      item_4: 'Atraso en días',
      item_5: 'Ejecución física',
      item_6: 'GUID'
    }
  },
  english: {
    title: [
      'Block',
      'Description'
    ],
    table: {
      item_1: 'Scheduled date',
      item_2: 'Rescheduled date',
      item_3: 'Executed date',
      item_4: 'Delay days',
      item_5: 'Physical execution',
      item_6: 'GUID'
    }
  }
}

export const worktableTranslations = {
  spanish: {
    export: {
      action: 'Exportar tabla'
    }
  },
  english: {
    export: {
      action: 'Export table'
    }
  }
}
